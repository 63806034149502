import { Grid, Typography, Box, useTheme } from '@mui/material'
import React from 'react'
import { MotionViewport, varFade } from 'src/components/animate'
import { m } from 'framer-motion'
import { useLocales } from 'src/locales';


const PerfectMatch = () => {
    const { currentLang, t } = useLocales();
    const theme = useTheme();

    return (
        <div>
            <Grid component={MotionViewport} container spacing={5}
                sx={{
                    backgroundColor: theme.palette.mode === 'light' ? '#FBF9FF' : '',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: {md:'6rem'},
                    padding: {
                        lg: '0',
                        xs: '4rem 0',
                    },
                }}>
                <Grid
                    item
                    xs={11}
                    md={5}
                    order={{ xs: 1, md: 1 }}
                    display={'grid'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <m.span variants={varFade().inUp}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography gutterBottom variant="h2" fontWeight={800} sx={{ textAlign: { xs: 'center' } }}>
                                <div dangerouslySetInnerHTML={{ __html: t('Timezzi_Is_Your_perfect_Match') }} />
                                
                            </Typography>
                            <Typography gutterBottom variant="h5" fontWeight={400}>
                                {/* {t(
                                    "If_You_Run_A_beauty"
                                )} */}
                                 <div dangerouslySetInnerHTML={{ __html: t("If_You_Run_A_beauty") }} />
                            </Typography>
                        </Box>
                    </m.span>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4}
                    order={{ xs: 2, md: 2 }}
                    sx={{
                        //   display: is500px ? 'block' : 'none',
                        // marginTop: {
                        //     // xs: '-10rem',
                        //     sm: '-10rem',
                        //     md: '0rem',
                        //     lg: '0rem',
                        // },
                    }}
                >
                    <m.span variants={varFade().inUp}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                loading='lazy'
                                style={{
                                    objectFit: 'contain',
                                    padding: '0rem',
                                    aspectRatio: '7 / 7',
                                }}
                                src={'/assets/images/landing/partner/perfectMatch.png'}
                                // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727074690445.png'}
                                alt="Image"
                            />
                        </Box>
                    </m.span>
                </Grid>


            </Grid>
        </div>
    )
}

export default PerfectMatch