import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { format } from 'date-fns';
import { useRouter } from 'src/routes/hooks';
import { Link } from 'react-router-dom';
import { color } from 'framer-motion';
import { display, fontSize, fontWeight } from '@mui/system';
// ----------------------------------------------------------------------
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { paths } from 'src/routes/paths';
import { useState, useCallback } from 'react';
// @mui
import { MotionViewport, varFade } from 'src/components/animate';
import Calendar3D from '../../assets/Calendar_3d.jpg';
import Open from '../../assets/open.png';
import Close from '../../assets/close.png';
import Card from '@mui/material/Card';
import { m } from 'framer-motion';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { setBranch } from 'src/store/slices/userSlice';
import { Alert, AlertTitle, Container, Grid, Stack, useTheme } from '@mui/material';
import { FadeMenu } from 'src/components/menu';
import moment from 'moment-timezone';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  counter,
}) {
  const {
    branchId,
    name,
    address,
    categories,
    contactNumber,
    gender,
    image,
    isActive,
    likes,
    openingHours,
    vacations,
    business,
  } = row;

  const router = useRouter();
  const confirm = useBoolean();

  const theme = useTheme();

  console.log('business data = ', row);

  const quickEdit = useBoolean();

  const dispatch = useDispatch();

  const popover = usePopover();

  const handleNavigate = useCallback(() => {
    dispatch(setBranch(row));
    router.push(`${paths.dashboard.business.profile}/${row._id}/profile`);
  }, [dispatch, router, row]);

  const [state, setState] = React.useState(true);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Container component={MotionViewport}>
      <Grid container spacing={2} alignItems="center" justifyContent={'space-between'}>
        <Grid xs={12} md={6} lg={6} marginLeft={'1rem'}>
          <m.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: [800], textAlign: 'center', marginTop: '1.5rem' }}
            >
              Vacations
            </Typography>

            {vacations.length >= 1 ? (
              vacations?.map((iter) => (
                <Card
                  key={iter.id}
                  onKeyDown={toggleDrawer(anchor, false)}
                  onClick={toggleDrawer(anchor, false)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    boxShadow: 3,
                  }}
                >
                  <Label
                    variant="soft"
                    color={'primary'}
                    sx={{ flexShrink: 0, fontSize: '.8rem', mx: 1 }}
                  >
                    {`Closed at `}
                    {`${format(new Date(iter.date), 'dd MMM yyyy')}`}
                  </Label>
                  <Label variant="soft" color={'warning'} sx={{ flexShrink: 0, fontSize: '.8rem' }}>
                    {iter?.allDay ? 'All Day' : iter.startTime + ' - ' + iter.endTime}
                  </Label>
                </Card>
              ))
            ) : (
              <Card
                onKeyDown={toggleDrawer(anchor, false)}
                onClick={toggleDrawer(anchor, false)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 1,
                }}
              >
                <Label variant="soft" color={'success'} sx={{ flexShrink: 0, fontSize: '.8rem' }}>
                  {'Business has no vacations'}
                </Label>
              </Card>
            )}
          </m.div>
        </Grid>
        <Grid xs={12} md={5} lg={5}>
          <m.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            {' '}
            <Typography
              variant="h5"
              sx={{ fontWeight: [800], textAlign: 'center', marginTop: '1.5rem' }}
            >
              Business Hours
            </Typography>
            {row?.openingHours.length >= 1 ? (
              row?.openingHours.map((iter) => (
                <Card
                  key={iter.id}
                  onKeyDown={toggleDrawer(anchor, false)}
                  onClick={toggleDrawer(anchor, false)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    boxShadow: 3,
                  }}
                >
                  <img
                    className="iconBox"
                    style={{ borderRadius: '.5rem', padding: '.4rem' }}
                    alt="icon"
                    src={iter?.isOpen ? Open : Close}
                    width={'70rem'}
                  />

                  <Box sx={{ marginLeft: '.4rem' }}>
                    <Typography
                      component="div"
                      variant="h4"
                      fontWeight={800}
                      color={theme.palette.primary.light}
                      textAlign="center"
                    >
                      {iter.day}
                    </Typography>
                    <Stack
                      direction={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      margin={'.5rem'}
                    >
                      <Iconify
                        icon="solar:clock-circle-bold"
                        sx={{ flexShrink: 0, marginRight: '.3rem' }}
                      />
                      {`${iter.startTime} - ${iter.endTime}`}
                    </Stack>
                  </Box>
                </Card>
              ))
            ) : (
              <Alert
                onKeyDown={toggleDrawer(anchor, false)}
                onClick={toggleDrawer(anchor, false)}
                severity="warning"
                sx={{ margin: '.5rem' }}
              >
                Fields not set.
              </Alert>
            )}
          </m.div>
        </Grid>
      </Grid>
    </Container>
  );

  const handleClick = () => {
    console.log(row);
  };
  const getActivePlanStrings = () => {
    const plan = business?.activePlan?.plan;
    if (!plan) return { full: 'N/A', short: 'N/A' };
    const full = `${plan.name}${plan.trialDays ? ` - ${plan.trialDays} days trial` : ''}`;
    const short = full.length > 15 ? `${full.slice(0, 15)}...` : full;
    return { full, short };
  };

  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{branchId}</TableCell>

        {/* <TableCell sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Avatar variant="rounded" alt={name} src={image} sx={{ mr: 2 }} /> {name}
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src={image} sx={{ mr: 2 }} />

          <ListItemText
            primary={name}
            secondary={business?.email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{business?.ownerName}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{contactNumber}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{moment(business?.joined).format('ll')}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {moment(business?.createdAt).format('ll')}
        </TableCell>

        <TableCell
          style={{
            maxWidth: '15rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {' '}
          <Tooltip title={address?.line1} placement="top-start">
            {address?.line1}
          </Tooltip>
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            '::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {gender}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <React.Fragment key={'top'}>
            <Button variant="contained" onClick={toggleDrawer('top', true)}>
              {'View'}
            </Button>
            <Drawer
              sx={{
                '.MuiPaper-root': {
                  borderRadius: '.5rem',
                  maxWidth: '60rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '.5rem',
                },
              }}
              anchor={'top'}
              open={state['top']}
              onClose={toggleDrawer('top', false)}
            >
              {list('top')}
            </Drawer>
            {/* <Drawer
              sx={{
                '.MuiPaper-root': {
                  borderRadius: '.5rem',
                  maxWidth: '60rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '.5rem',
                },
              }}
              anchor={'top'}
              open={state['top']}
              onClose={toggleDrawer('top', false)}
            >
              {list('top')}
            </Drawer> */}
          </React.Fragment>
        </TableCell>

        <TableCell>
          {/* <Label variant="soft" color={(isActive ? 'success' : 'error') || 'default'}>
            {isActive ? 'Active' : 'Inactive'}
          </Label> */}
          <FadeMenu row={row} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Tooltip title={getActivePlanStrings()?.full} placement="top-start">
          {getActivePlanStrings()?.short}
          </Tooltip>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="View Profile" placement="top" arrow>
            <IconButton>
              <Iconify
                onClick={handleNavigate}
                width="1.8rem"
                icon="solar:move-to-folder-bold-duotone"
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
