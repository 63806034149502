import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import home from '../homebanner.svg';
import homeApple from '../homeApple.png';
import { borderRadius, flexbox, maxWidth, width } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import CustomCarousalApp from '../CustomCarousal/js';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { useMemo } from 'react';

export default function SeamLessIntegration() {
  const theme = useTheme();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        margin: '5rem 0',
      }}
    >
      <Grid mt={8} item xs={12} md={4} order={{ xs: 2, md: 1 }}>
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
              loading='lazy'
                src={'/assets/images/landing/partner/seemlessIntegration.png'}
                // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727074964633.png'}
                alt="Phone"
                style={{ aspectRatio: '8 / 9', height: 'auto', objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </m.span>
      </Grid>

      <Grid
        item
        xs={11}
        md={5}
        order={{ xs: 1, md: 2 }}
        display={'grid'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <m.span variants={varFade().inUp}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography gutterBottom variant="h1" fontWeight={800} sx={{ textAlign: { xs: 'center' } }}>
              <div dangerouslySetInnerHTML={{ __html: t('SEAMLESS_INTEGRATION_GROWTH') }} />
            </Typography>
            <Typography gutterBottom variant="h5" fontWeight={400}>
              {t(
                'Unlock growth opportunities with our seamless Facebook and Instagram calendar integrations.'
              )}
            </Typography>
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}
