import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';
import CustomCarousalApp from '../CustomCarousal/js';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import { Button, IconButton } from '@mui/material';
import { RouterLink } from 'src/routes/components';
import { Link } from 'react-router-dom';

export default function HomeHero() {
  const theme = useTheme();

  const mdXs = useResponsive('up', 'xs');
  const lgUp = useResponsive('up', 'lg');

  const settings = useSettingsContext();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      alignItems="center"
      sx={{
        margin: {
          xs: '5rem 0',
          sm: '5rem 0',
          md: '7rem 0',
          lg: '7rem 0',
        },
      }}
    >
      <Grid item xs={12} md={4} order={{ xs: 1, md: 1 }}>
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              margin: {
                lg: '0rem 3rem',
                md: '0rem 3rem',
                sm: '0rem 0rem',
                xs: '0rem 0rem',
              },
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
            }}
          >
            <Typography
              textAlign={'center'}
              gutterBottom
              variant="h2"
              whiteSpace={'wrap'}
              fontWeight={900}
            >
              <div dangerouslySetInnerHTML={{ __html: t('BOOK_YOUR_WAY_YOUR_DAY_WITH_EASE') }} />
            </Typography>
            <Typography sx={{ mt: 3, textAlign: { xs: "center" } }}>
              <div dangerouslySetInnerHTML={{ __html: t('Seamless_Scheduling') }} />
            </Typography>

            {/* <Box sx={{display:'flex', flexDirection: {md:'row',xs:"column"}, justifyContent:{md: 'start',xs:'center'}, alignItems: 'center',mt:{xs:1,md:0}}}>
              <Link component={RouterLink} to={'/'}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    marginX: 'auto',
                    maxWidth: '30rem',
                    marginTop: '.5rem',
                    border: '2px solid #7955CF',
                    borderRadius: '1rem',
                  }}
                >
                  <img width={30} sx={{ color: '#fafafa' }} src="/assets/icons/app/apple.svg" />
                  <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', ml: { md: 4, xs: 2 } }}>
                    <Typography>
                      Get it on the
                    </Typography>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>App Store</Typography>
                  </Box>
                </Button></Link>
              <Link component={RouterLink} to={'/'}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    marginX: 'auto',
                    maxWidth: '15rem',
                    marginTop: '.5rem',
                    border: '2px solid #7955CF',
                    borderRadius: '1rem',
                    marginLeft: 2

                  }}
                >
                  <img width={30} sx={{ color: '#fafafa' }} src="/assets/icons/app/playstore.svg" />
                  <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', ml: { md: 2, xs: 0 } }}>
                    <Typography>
                      Get it on the
                    </Typography>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Google play</Typography>
                  </Box>
                </Button>
              </Link>
            </Box> */}
          </Box>
        </m.span>
      </Grid>
      <Grid item xs={12} md={4} order={{ xs: 2, md: 2 }}>
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              padding: {
                xs: '0rem',
                sm: '0rem',
                md: '0rem',
                lg: ' 0 2rem',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                '&:hover': {
                  transform: 'scale(1.50)',
                },
                objectFit: 'contain',
                aspectRatio: '8 / 9',
              }}
              loading='lazy'
              src={'/assets/images/landing/home/bookYourDay.jpg'}
              // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075492544.png'}
              alt="Image"
            />
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}
