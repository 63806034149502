import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { borderRadius, display, flexbox, maxWidth, useMediaQuery, width } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { transform } from 'lodash';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

export default function NewReasonToJoin() {
  const theme = useTheme();

  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const is500px = useMediaQuery('(min-width:500px)');

  const settings = useSettingsContext();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.mode === 'light' ? '#FBF9FF' : '',
        paddingY: '2rem',
        margin: {
          md: '7rem 0',
        },
      }}
    >
      <Grid
        item
        xs={11}
        md={4}
        order={{ xs: 1, md: 1 }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <m.span variants={varFade().inUp}>
          <Box
            display={'flex'}
            sx={{
              marginBottom: {
                xs: '2rem',
              },
              justifyContent: !is500px ? 'center' : 'start',
              alignItems: 'center',
            }}
          >
            {/* <Typography
              gutterBottom
              fontWeight={900}
              sx={{
                fontSize: {
                  lg: '13rem',
                  md: '13rem',
                  sm: '10rem',
                  xs: '8rem',
                },

                background: 'linear-gradient(100deg, #7955CF 40%, #f5f5f5 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              1
            </Typography>
            <Typography
              gutterBottom
              variant="h1"
              sx={{
                maxWidth: {
                  lg: '20rem',
                  md: '20rem',
                  sm: '12rem',
                  xs: '12rem',
                },
                minWidth: {
                  lg: '20rem',
                  md: '20rem',
                  sm: '20rem',
                  xs: '10rem',
                },

                textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
              }}
              fontWeight={900}
            >
              <div dangerouslySetInnerHTML={{ __html: t('REASON_TO_JOIN_US') }} />
            </Typography> */}

          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
              marginTop: '-3rem',
              marginX: {
                lg: '0rem',
                md: '0rem',
                sm: '1rem',
                xs: '1rem',
              },
              justifyContent: !is500px ? 'center' : 'start',
              alignItems: !is500px ? 'center' : 'start',
            }}
          >
            <Typography
              gutterBottom
              variant="h3"
              whiteSpace={'wrap'}
              fontWeight={900}>
              "{t('The all-in-one platform making navigation a breeze')}"
            </Typography>

            <Typography marginTop={'.5rem'} gutterBottom variant="h6" fontWeight={400}>
              <div dangerouslySetInnerHTML={{ __html: t('UNLOCK_CONVENIENCE') }} />
            </Typography>
            {is500px && (
              <Link component={RouterLink} to={'/partner'}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={
                    <IconButton>
                      <Iconify width={30} sx={{ color: '#fafafa' }} icon="fa6-solid:handshake" />
                    </IconButton>
                  }
                  style={{
                    marginX: 'auto',
                    maxWidth: '12rem',
                    marginTop: '.5rem',
                    border: '2px solid #7955CF',
                    borderRadius: '1rem',
                  }}
                >
                  {t('Become Partner')}
                </Button>
              </Link>
            )}
          </Box>
        </m.span>
      </Grid>
      <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 2 }}>
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              padding: {
                xs: '0rem',
                sm: '0rem',
                md: '0rem',
                lg: ' 0 2rem',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: { xs: '2rem', md: 0 }
            }}
          >
            <img
              style={{
                width: '100%',
                objectFit: 'contain',
                aspectRatio: '9 /9 ',
              }}
              loading='lazy'
              src={'/assets/images/landing/home/reasonToJoin.png'}
              // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075605324.png'}
              alt="Image"
            />
          </Box>
        </m.span>
      </Grid>
      {!is500px && (
        <Grid
          item
          xs={12}
          md={4}
          order={{ xs: 3, md: 1 }}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Link component={RouterLink} to={'/partner'}>
            <Button
              color="primary"
              variant="contained"
              startIcon={
                <IconButton>
                  <Iconify width={30} sx={{ color: '#fafafa' }} icon="fa6-solid:handshake" />
                </IconButton>
              }
              style={{
                marginX: 'auto',
                maxWidth: '12rem',
                marginTop: '.5rem',
                border: '2px solid #7955CF',
                borderRadius: '1rem',
              }}
            >
              Become Partner
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
}
