import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Button,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';

import { RHFTextField } from 'src/components/hook-form';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { useRef } from 'react';
import Slider from 'react-slick';
import '../dotsStyle.css';
import '../arrowStyle.css';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';

export default function SectionSlider2() {
  const sliderRef = useRef(null);

  const theme = useTheme();

  const { t } = useLocales();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 300,
    // autoplaySpeed: 3000,
    // cssEase: 'linear',
    // rtl: true,
  };

  return (
    <>
      <Grid
        component={MotionViewport}
        container
        spacing={5}
        justifyContent="center"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: {
            xs: '5% 0',
            sm: '5% 0',
            md: '5% 0',
            lg: '5% 0',
          },
          marginX: 'auto',
          marginBottom: '5rem',
        }}
      >
        <Grid item xs={12} md={4} lg={4}
          order={{ xs: 2, md: 2 }}
        >
          <m.span variants={varFade().inUp}>
            <Box
              sx={{
                padding: {
                  xs: '0rem',
                  sm: '0rem',
                  md: '0rem',
                  lg: ' 0 2rem',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  objectFit: 'contain',
                  aspectRatio: '8 / 9',
                }}
                loading='lazy'
                src={'/assets/images/landing/home/customercagtory.png'}
                // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075605324.png'}
                alt="Image"
              />

            </Box>
          </m.span>
        </Grid>
        <Grid
          item
          xs={11}
          md={5}
          order={{ xs: 1, md: 1 }}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <m.span variants={varFade().inUp}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography gutterBottom variant="h2" fontWeight={800}>
                <div dangerouslySetInnerHTML={{ __html: t('Explore_Service_Customized') }} />

              </Typography>
              <Typography gutterBottom variant="h5" fontWeight={400}>
                {t("Simply_Find_The_Right" )}
              </Typography>
            </Box>
          </m.span>
        </Grid>
        {/* <Grid item xs={11} md={11} lg={11}>
          <Slider ref={sliderRef} {...settings}>
            {cardData.map((card, index) => (
              <CarousalItem t={t} key={card.id} card={card} index={index} />
            ))}
          </Slider>
        </Grid> */}
      </Grid>
    </>
  );
}

function CarousalItem({ card, index, t }) {
  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      sx={{
        display: 'flex',
        marginX: 'auto',
        marginBottom: '5rem'
      }}
    >
      <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }} display={'grid'} alignItems={'center'} sx={{
        minHeight: {
          xs: '25rem',
        },
      }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            sx={{
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
            }}

            gutterBottom
            variant="h1"
            fontWeight={800}
          >
            <div dangerouslySetInnerHTML={{ __html: t(card?.title) }} />
          </Typography>
          <Typography gutterBottom variant="h5" fontWeight={400}>
            {t(card?.description)}
          </Typography>
        </Box>
      </Grid>
      <Grid
        sx={{
          marginTop: {
            lg: '0rem',
            md: '0rem',
            sm: '-3rem',
            xs: '-3rem',
          },
        }}
        item
        xs={12}
        md={4}
        order={{ xs: 2, md: 2 }}
      >
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '25rem',
                objectFit: 'contain',
                aspectRatio: '5 / 5',
              }}
              loading="lazy"
              // src={`/assets/images/landing/about/aboutSlider2/${card?.id}.png`}
              src={card?.src}
              alt="Image"
            />
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}

CarousalItem.propTypes = {
  item: PropTypes.object,
};

const cardData = [
  {
    id: 1,
    title: 'IMAGINE_BOOKED_SERVICE',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727078082797.png',
    description: `Whether it’s a haircut, a message, or any other service, Timezzi brings the best right to your doorstep or arranges your visit to a salon or gym.`,
  },
  {
    id: 2,
    title: 'EFFORTLESS_CONNECTIONS',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727078104630.png',
    description: `Connecting with top-rated professionals has never been easier or more seamless.`,
  },
  {
    id: 3,
    title: 'UNMISSABLE_DAILY_DEALS',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727078120440.png',
    description: `Every day brings new opportunities to save with Timezzi. Discover incredible daily deals that you simply can’t miss.`,
  },
];
