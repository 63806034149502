import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import homeApple from '../homeApple.png';
import { borderRadius, flexbox, width } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';

import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import DownloadButtons from '../download_buttons';
import { store_links } from 'src/assets/data/stores';

export default function DownloadBusinessApp() {
  const theme = useTheme();

  const { t } = useLocales();

  return (
    <Container
      component={MotionViewport}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      
      }}
    >
      <Grid
        container
        spacing={5}
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '10% 0' }}
        sx={{
          background: {
            lg: 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)',
            md: 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)',
            sm: 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)',
            xs: 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)',
          },
          border: '1px solid #5e35b1',
        }}
        borderRadius={'2rem'}
      >
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <m.span variants={varFade().inUp}>
            <Typography
              textAlign={'center'}
              gutterBottom
              variant="h1"
              fontWeight={900}
              sx={{
                color : "white",
                textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)',
                textAlign: {
                  lg: 'start',
                  md: 'start',
                  sm: 'center',
                  xs: 'center',
                },
                fontWeight: 800,
                whiteSpace: 'wrap',
                fontSize: {
                  xs: '2rem', // smaller font size for mobile
                  sm: '3rem', // medium font size for tablets
                  md: '3.5rem', // larger font size for desktop
                },
                lineHeight: 1.2, // adjust line height as needed
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: t('PARTNER_DOWNLOAD_BUSINESS_APP_NOW') }} />
            </Typography>
          </m.span>
          <m.span variants={varFade().inUp}>
            <Box
              display={'flex'}
              rowGap={2}
              columnGap={2}
              marginTop="1rem"
              flexWrap={'wrap'}
              sx={{
                justifyContent: {
                  lg: 'start',
                  md: 'start',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              <DownloadButtons
               onClick={() => window.open(store_links.businessAppStore, '_blank')}
                image={'/assets/icons/app/apple.svg'}
                title="App Store"
                description="Download on the"
              />
              <DownloadButtons
               onClick={() => window.open(store_links.customerPlaystore, '_blank')}
                image={'/assets/icons/app/playstore.svg'}
                title="Play Store"
                description="Get it on"
              />
            </Box>
          </m.span>
        </Grid>
        <Grid mt={8} item xs={12} md={6} order={{ xs: 1, md: 1 }}>
          <m.span variants={varFade().inUp}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  objectFit: 'contain',
                  aspectRatio: '5 / 5',
                }}
                loading='lazy'
                // src={'/assets/images/landing/partner/downloadBusiness.png'}
                src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075398457.png'}
                alt="Image"
              />
            </Box>
          </m.span>
        </Grid>
      </Grid>
    </Container>
  );
}
