import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import DownloadButtons from '../download_buttons';
import { store_links } from 'src/assets/data/stores';

export default function CustomerBusiness() {
  const theme = useTheme();

  const { t } = useLocales();
  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      alignItems="stretch"
      style={{ margin: '10% 0' }}
    >
      {CardData?.map((iter, index) => (
        <Grid key={index} item xs={12} sm={8} md={5} lg={4}
          sx={{ direction: 'ltr', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <m.span variants={varFade().inUp}>
            <Box
              sx={{
                background:
                  theme.palette.mode === 'light'
                    ? theme.direction === 'rtl'
                      ? 'linear-gradient(to bottom left, transparent 100%, #7955CF 0%, #9275D6 30%, #ede7f6 60%, #fafafa 90%)'
                      : 'linear-gradient(to bottom right, #7955CF 0%, #9275D6 30%, #ede7f6 60%, #fafafa 90%, transparent 100%)'
                    : 'linear-gradient(to top right, #7955CF 0%, #9275D6 30%, #ede7f6 60%, #fafafa 90%, transparent 100%)',
                // border: '1px solid #5e35b1',
                borderRadius: "2rem",
                boxShadow: '0 0px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    // height: '40rem',
                    marginLeft: {
                      lg: '0',
                      md: '0',
                      sm: '-2rem',
                      xs: '-2rem',
                    },
                  }}
                >
                  <img
                    src={iter.images}
                    alt="Phone"
                    style={{
                      objectFit: 'cover',
                      padding: '3rem',
                      aspectRatio: '9 / 9',
                    }}
                  />
                </Box>
              </Box>
              <Typography
                gutterBottom
                variant="h3"
                sx={{
                  marginTop: {
                    lg: '-2rem',
                  },
                  textShadow: '1px 1px 1px rgba(33, 33, 33, 0.5)',
                  textAlign: 'center',
                  padding: '0rem 0rem',
                  fontWeight: [900],
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: t(`DOWNLOAD_${iter.title}_APP_NOW`) }} />
              </Typography>
              <Box
                padding={'0rem 1rem'}
                display={'flex'}
                columnGap={2}
                rowGap={1}
                marginTop="1rem"
                paddingBottom="2rem"
                flexWrap={'wrap'}
                justifyContent="center"
              >
                <DownloadButtons
                  onClick={() => window.open(`${iter.title === 'CUSTOMER' ? store_links.customerAppStore : store_links.businessAppStore}`, '_blank')}
                  image={'/assets/icons/app/apple.svg'}
                  title="App Store"
                  description="Download on the"
                />
                <DownloadButtons
                  onClick={() => window.open(`${iter.title === 'CUSTOMER' ? store_links.customerPlaystore : store_links.businessPlayStore}`, '_blank')}
                  image={'/assets/icons/app/playstore.svg'}
                  title="Play Store"
                  description="Get it on"
                />
              </Box>
            </Box>
          </m.span>
        </Grid>
      ))}
    </Grid>
  );
}

const CardData = [
  {
    id: 0,
    title: 'CUSTOMER',
    images: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727078316394.png',
  },
  {
    id: 1,
    title: 'BUSINESS',
    images: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727078331815.png',
  },
];
