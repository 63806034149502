import { useCallback, useRef } from 'react';

// ----------------------------------------------------------------------


export function useDoubleClick({ click, doubleClick, timeout = 250 }) {
  const clickTimeout = useRef(null);

  const handleClick = useCallback(
    (...args) => (event) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
        doubleClick?.(event, ...args); // Fire double-click handler with arguments
      } else {
        clickTimeout.current = setTimeout(() => {
          click?.(event, ...args); // Fire single-click handler with arguments
          clickTimeout.current = null;
        }, timeout);
      }
    },
    [click, doubleClick, timeout]
  );

  return handleClick;
}