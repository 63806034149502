import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { m } from 'framer-motion';
import { LazyMotion, domAnimation } from 'framer-motion';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { CardHeader, IconButton, useMediaQuery } from '@mui/material';
import { useLocales } from 'src/locales';
import { t } from 'i18next';

import { motion } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { _homePlans } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import { varFade, MotionViewport } from 'src/components/animate';
import { Grid } from '@mui/material';
import { borderRadius, display, width } from '@mui/system';
import { useSettingsContext } from 'src/components/settings';
import { useGetAllPublicSubscriptionsPlansQuery } from 'src/store/Reducer/subscriptions-plans';
import { use } from 'react';
import axios from 'axios';
import { set } from 'lodash';
import { card } from 'src/theme/overrides/components/card';

// ----------------------------------------------------------------------

export default function HomePricing() {
  const mdUp = useResponsive('up', 'md');
  const [plans, setplans] = useState([]);
  const settings = useSettingsContext();

  const is1400px = useMediaQuery('(min-width:1400)');

  console.log('1400px = ', is1400px);

  const theme = useTheme();

  console.log('theme = ', theme);

  const { t } = useLocales();

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  console.log('plans----', plans?.length > 3 ? 3 : plans?.length);

  const carousel = useCarousel({
    slidesToShow: plans?.length > 3 ? 3 : plans?.length,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: plans?.length > 3 ? 3 : plans?.length,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  const fetchPlans = async () => {
    try {
      const {
        data: { body },
      } = await axios.get(`${process.env.REACT_APP_HOST_API}/subscription-plan/all/public`);
      let plansData = body?.data;
      const sortOrder = ['free', 'advanced', 'premium'];
      const sortedPlans = plansData?.sort((a, b) => {
        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
      });
      setplans(sortedPlans);
    } catch (error) {
      console.log('Error fetching plans', error.message);
    }
  };
  useEffect(() => {
    fetchPlans();
  }, []);
  function capitalizeWords(str) {
    return str
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }
  const cardData = plans.map((plan, index) => {
    if (plan?.type === 'free') {
      return {
        id: plan.id || 1,
        heading1: capitalizeWords(plan?.name),
        heading2: capitalizeWords(plan?.type),
        heading3: capitalizeWords(plan?.type),
        subDescription: '',
        description: plan?.description?.split('\n') || '',
        buttonText: 'Start for free',
      };
    } else if (plan?.type === 'advanced') {
      return {
        id: plan.id || 2,
        heading1: capitalizeWords(plan?.name),
        heading2: capitalizeWords(plan?.type),
        originalPrice: plan?.originalPrice>0?plan?.originalPrice:null,
        currency: plan?.currency || 'AED',
        heading3: `${plan?.price || '0'} ${plan?.currency || 'AED'} /${plan?.interval}`,
        subDescription: `+ ${plan?.additionalPrice} ${plan?.currency || 'AED'
          } per additional user`,

        trail: plan?.trialDays,
        description: plan?.description?.split("\n") || '',
        // buttonText: 'Start your free trial',
        buttonText: 'Boost Your Growth',
      };
    }
    else if (plan?.type === 'premium') {
      return {
        id: plan.id || 3,
        heading1: capitalizeWords(plan?.name),
        heading2: capitalizeWords(plan?.type),
        heading3: `${plan?.price || '0'} %`,
        subDescription: `+ ${plan?.additionalPrice} ${plan?.currency || 'AED'
          }  per online transaction`,
        description: plan?.description?.split('\n') || '',
        // buttonText: 'Get Premium',
        buttonText: 'Expand Your Impact',
      };
    }
  });
  return (
    <Grid
      component={MotionViewport}
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        margin: '5rem 0rem',
        marginTop: '10rem',
      }}
    >
      <Grid
        sx={{
          marginX: {
            lg: '0rem',
            md: '0rem',
            xs: '1rem',
            sm: '1rem',
          },
        }}
        item
        xs={12}
        md={12}
      >
        <m.span variants={varFade().inUp}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography gutterBottom color="#7955CF" variant="h1" fontWeight={800}>
              <div dangerouslySetInnerHTML={{ __html: t('OUR_PRICING_PLAN') }} />
            </Typography>
            <Typography gutterBottom variant="h6" fontWeight={400}>
              {t(
                'Choose the package that best fits your business goals, budget and operational needs based on the descriptions provided'
              )}
            </Typography>
          </Box>
        </m.span>
      </Grid>
      <Grid item xs={12} md={is1400px ? 8 : 12} lg={9} sm={12} marginTop={'3rem'} height={'100%'}>
        {!isLgUp ? (
          <CardHeader
            sx={{}}
            action={
              <CarouselArrows
                sx={{ backgroundColor: '#7955CF', color: '#fafafa', borderRadius: '2rem' }}
                onNext={carousel.onNext}
                onPrev={carousel.onPrev}
              />
            }
          />
        ) : (
          ''
        )}

        <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
          {cardData?.map((card, index) => (
            <CarousalItem
              settings={settings}
              key={card.id}
              card={card}
              index={index}
              theme={theme}
            />
          ))}
        </Carousel>

        <Typography
          component="div"
          variant="h5"
          fontWeight={400}
          marginX={5}
          textAlign={'center'}
          sx={{ mb: 0.1, marginTop: '1.5rem' }}
        >
          {t('*Rates are exclusive of 5% sales tax')}
        </Typography>
      </Grid>
    </Grid>
  );
}

function CarousalItem({ card, index, theme, settings }) {
  return (
    <Box
      key={card.id}
      sx={{
        boxShadow: '2px 5px 15px rgba(0, 0, 0, 0.2)',
        background:
          index === 0
            ? '#fafafa'
            : settings.themeDirection === 'rtl'
            ? 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)'
            : 'linear-gradient(to right bottom, #190B3A 0%, #7955CF 100%)',
        color: index !== 0 ? '#fafafa' : '#212B36',
        borderRadius: '1rem',
        border: index === 0 ? '1px solid #e0e0e0' : 'none',
        margin: 2,
        height: '520px',
        padding: '10px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Align content vertically and push the button to the bottom
      }}
    >
      <Box
        sx={{
          flexGrow: 1, // This allows the content to take up available space
          padding: '1.5rem',
          paddingBottom: '.5rem',
          gap: 1,
        }}
      >
        {index === 1 && (
          <Typography
            sx={{
              top: { xs: '3px', md: 0 },
              left: '50%',
              transform:
                settings.themeDirection === 'ltr'
                  ? 'translate(-30%, 0) rotate(-30deg)'
                  : 'translate(-30%, 0) rotate(30deg)',
              marginTop: '-1rem',
              backgroundColor: '#7955CF',
              textAlign: 'center',
              width: { xs: '14rem', sm: '14rem', md: '14rem', lg: '14rem' },
              color: '#fff',
              padding: '0.25rem 0',
            }}
            variant="h6"
            fontWeight={700}
          >
            {t('Popular')}
          </Typography>
        )}

        <Box display={'flex'} flexDirection="column" alignItems={'start'} gap={1}>
          <Typography marginTop={4} variant="h6" fontWeight={500}>
            {t(card?.heading1)}
          </Typography>
          <Typography variant="h6">{t(card?.heading2)}</Typography>
          {index === 1 && card?.originalPrice && (
            <Typography
              variant="h3"
              sx={{
                textDecoration: 'line-through',
                textDecorationColor: 'red',
                textDecorationStyle: 'solid',
                marginBottom: '-4px',
                lineHeight: '1',
              }}
            >
              {t(card?.originalPrice)} {card?.currency}
            </Typography>
          )}
          <Typography variant="h4">{t(card?.heading3)}</Typography>
          <Typography variant="subtitle1" fontWeight={400}>
            {t(card?.subDescription) ? t(card?.subDescription) : ''}
          </Typography>
          {index === 1 && (
            <Typography sx={{ fontWeight: 600, marginTop: '-5px' }}>
              (Try Free for <span style={{ color: 'red' }}>90 Days</span>)
            </Typography>
          )}
          {index === 1 && (
            <Typography sx={{ lineHeight: '1', marginTop: '-5px', fontWeight: 600 }}>
              Lifetime offer valid during Ramadan
            </Typography>
          )}
          {card?.description?.map((item) => (
            <Typography variant="subtitle1" fontWeight={400}>
              {t(item)}
            </Typography>
          ))}
        </Box>
      </Box>

      {/* Button at the bottom */}
      <LazyMotion features={domAnimation}>
        <motion.a
          whileHover={{ scale: 1.1 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            endIcon={
              <IconButton
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  padding: 0,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  borderRadius: '50%',
                  border: '1px solid #fafafa',
                }}
                variant="soft"
              >
                <Iconify width={30} sx={{ color: '#fafafa' }} icon="solar:arrow-right-linear" />
              </IconButton>
            }
            style={{
              marginTop: '.2rem',
              marginBottom: '.5rem',
              backgroundColor: 'transparent',
              border: index !== 0 ? '1px solid #fafafa' : '2px solid #7955CF',
              borderRadius: '4rem',
              color: index === 0 ? '#7955CF' : '#fafafa',
              fontSize: '1rem',
              justifyContent: 'space-between', // Ensure space between text and endIcon
            }}
            variant="contained"
          >
            {t(`${card?.buttonText}`)}
          </Button>
        </motion.a>
      </LazyMotion>
    </Box>
  );
}


CarousalItem.propTypes = {
  item: PropTypes.object,
};
