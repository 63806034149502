import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
// _mock
import { _socials } from 'src/_mock';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
//
import ProfilePostItem from './profile-post-item';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { useAddBannerImageMutation, useBusinessSettingMutation, useGetBusinessByIdQuery } from 'src/store/Reducer/business';
import { Avatar, Dialog, IconButton, ListItemText, Skeleton, Tooltip } from '@mui/material';
import DisplaySubcategories from '../category/subcategories';
import DisplayBusinessSubcategories from './display-business-subcategories';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import { useBoolean } from 'src/hooks/use-boolean';
import SettingsFormModal from './settingsModal';
import { UploadBox } from 'src/components/upload';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ProfileHome() {
  const { branch } = useMockedBranch();

  console.log('branch=>', branch);


  const open = useBoolean();

  const quickCreate = useBoolean();

  const fileRef = useRef(null);

  const { user } = useMockedUser();


  const { data, status, refetch, isLoading } = useGetBusinessByIdQuery(branch._id, { refetchOnMountOrArgChange: true });
  console.log('data=>', data);
  const { socialLinks } = data ?? {};
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [uploadFile] = useUploadNewFileMutation();
  const [updateBusiness, { isSuccess }] = useBusinessSettingMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(URL.createObjectURL(file));
      setImage(file);

    }
  };
  const handleSaveBanner = async () => {
    let iconUpdate =
      data?.banner && data?.banner.slice(40, data?.banner.length);

    let picname = iconUpdate;

    if (typeof image === 'object') {
      picname !== 'noimg.png' && await deleteFile(picname);
      const result = await uploadFile(image);
      await updateBusiness({ _id: branch._id, data: { banner: result?.data?.body?.fileName } });
    }
  }
  const onClose = () => {
    open.onFalse();
  }

  useEffect(() => {
    if (data) {
      if (data.banner) {
        setImage(data.banner);
        setSelectedFile(data.banner);
      }
    }
    if (isSuccess) {
      refetch();
    }


  }, [data, isSuccess]);


  return (
    <>
      <Grid container spacing={3}>
        <Box sx={{ ml: 3, width: "100%", display: 'flex', flexDirection: { md: 'row', xs: "column" }, alignItems: { md: "center" }, justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h5" gutterBottom>
              About
            </Typography>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: 'background.paper',
                mb: 2,
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {branch?.description || 'This business has not provided a information yet.'}
              </Typography>
            </Card>
          </Box>
          <Box sx={{ mr: 2, display: 'flex', justifyContent: { xs: "end" } }}>
            {user?.user_type !== 0 && <Button variant="contained" color="primary" onClick={() => open.onTrue()}>
              {data?.banner ? "Edit Banner" : "Add Banner"}
            </Button>}
          </Box>
        </Box>

        <Grid xs={12} md={11} >

          <Typography variant='h4' sx={{ ml: 1, mt: 2, mb: 1 }}>Banner
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: '300px',
              minHeight: '300px',
              backgroundImage: data?.banner && !isLoading
                && `url(${data?.banner})`,
              backgroundSize: data?.banner ? '100% auto' : 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '5px',
              display: 'block',
              position: 'relative',
              marginBottom: '50px',
              imageRendering: '-webkit-optimize-contrast',
              '@media (min-width: 768px)': {
                backgroundSize: data?.banner ? 'cover' : 'contain',
              }
            }}
          >
            {/* Show Skeleton if Loading */}
            {isLoading && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  borderRadius: "5px",
                }}
              />
            )}
          </Box>

        </Grid>



        <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <Box>


            <Dialog open={open.value} onClose={onClose} fullWidth maxWidth="md">
              <Typography variant="h4" sx={{ textAlign: "center", marginTop: 2 }}>{data?.banner ? "Edit Banner" : "Add"}</Typography>


              <Stack direction="row" spacing={2} sx={{ m: 2 }}>

                <UploadBox
                  onDrop={handleDrop}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center">
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload Banner</Typography>
                    </Stack>
                  }
                  sx={{ flexGrow: 1, height: '100px', py: 2.5, mb: 1 }}
                />
              </Stack>
              {selectedFile && (
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "8px",
                    backgroundColor: "#f9f9f9",
                    m: 2
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold", textAlign: "left" }}>Preview:</Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      height: "auto",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={selectedFile}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "350px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </Box>
              )}

              <Box sx={{ marginRight: 2, display: 'flex', justifyContent: 'end', my: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSaveBanner}>
                  Save Banner
                </Button>
              </Box>

            </Dialog>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-between',
            // alignItems: 'start',
            // p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Box >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                padding: "10px",
              }}
            >
              <Box>
                <Typography color="info" variant="h5">
                  Categories
                </Typography>
              </Box>

              {user?.user_type === 2 && (
                <Box>
                  <Button
                    onClick={() => quickCreate.onTrue()}
                    variant="contained"
                    color="primary"
                  >
                    Setting
                  </Button>
                </Box>
              )}


            </Box>
            {status === 'pending' ? (
              <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
            ) : (
              <DisplayBusinessSubcategories subcategories={data?.categories} />
            )}{' '}
          </Box>


        </Grid >

        <Grid
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Typography color="info" variant="h5" sx={{ mb: 2 }}>
            Information
          </Typography>
          {status === 'pending' ? (
            <Stack spacing={2}>
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Item sx={{ display: 'flex' }}>
                <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
                <Typography>{data?.contactNumber}</Typography>
              </Item>

              <Item sx={{ display: 'flex' }}>
                <Iconify icon="dashicons:email" sx={{ mr: 2 }} />
                <Typography>{data?.business?.email}</Typography>
              </Item>

              {[
                { icon: 'devicon:facebook', link: socialLinks?.facebook, label: 'facebook' },
                {
                  icon: 'skill-icons:instagram',
                  link: socialLinks?.instagram,
                  label: 'instagram',
                },
                // { icon: 'logos:twitter', link: socialLinks?.twitter, label: 'twitter' },
                { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'linkedin' },
              ].map((social, idx) => (
                <Item sx={{ display: 'flex' }} key={idx}>
                  <Iconify icon={social.icon} color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
                  <a
                    href={`${social.link}` ?? '#'}
                    style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {social.link ? social.link : `Provide Social Links`}
                  </a>
                </Item>
              ))}

              <Item sx={{ display: 'flex' }}>
                <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
                <Tooltip title={data?.address?.line1}>
                  <Typography
                    sx={{
                      maxWidth: '15rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data?.address?.line1}
                  </Typography>
                </Tooltip>
              </Item>

              {/* {data?.description && (
                <Item sx={{ display: 'flex' }}>
                  <Iconify icon="f7:doc-on-clipboard-fill" sx={{ mr: 2 }} />
                  <Typography>{data?.description}</Typography>
                </Item>
              )} */}
            </Stack>
          )}
        </Grid>
      </Grid >

      {
        quickCreate.value && (
          <SettingsFormModal open={quickCreate.value} onClose={quickCreate.onFalse} />
        )
      }
    </>
  );
}

ProfileHome.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
