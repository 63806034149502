import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

export default function PartnerHomeHero() {
  const theme = useTheme();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      sx={{
        position: 'relative',
        // width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        sx={{
          marginLeft: {
            xs: '1rem',
          },
        }}
        item
        xs={12}
        md={5}
        order={{ xs: 1, md: 1 }}
        display={'grid'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <m.span variants={varFade().inUp}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              gutterBottom
              variant="h1"
              fontWeight={900}
              sx={{
                textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
                textAlign:{xs:"center"}
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: t('CREATE_IDEAL_BUSINESS') }} />
            </Typography>
          </Box>
        </m.span>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        order={{ xs: 2, md: 2 }}
        sx={{
          //   display: is500px ? 'block' : 'none',
          marginTop: {
            xs: '-10rem',
            sm: '-10rem',
            md: '0rem',
            lg: '0rem',
          },
        }}
      >
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
            loading='lazy'
              style={{
                objectFit: 'contain',
                padding: '0rem',
                aspectRatio: '9 / 9',
              }}
              src={'/assets/images/landing/partner/createYourBusiness.png'}
              // src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727074690445.png'}
              alt="Image"
            />
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}
