import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Unstable_Grid2';

import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';


import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  Rating,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Box, Modal
} from '@mui/material';
import axios from 'axios';
import DisplayBusinessSubcategories from '../display-business-subcategories';
import { border, borderRadius, Container, useMediaQuery } from '@mui/system';
import moment from 'moment-timezone';
import { m } from 'framer-motion';
import DownloadButtons from 'src/sections/timezzi-Landing-Page/download_buttons';
import { varFade } from 'src/components/animate';
import ServiceCards from './business-services-view';
import Slider from 'react-slick';
import { useDoubleClick } from 'src/hooks/use-double-click';
import { store_links } from 'src/assets/data/stores';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BUisnessPublicView() {
  const [branch, setbranch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectServ, setSelectServ] = useState(null);
  const [selectMemb, setSelectMemb] = useState(null);
  const [availableSlots, setAvailabeSlots] = useState([]);
  const [loadingAvailable, setLoadingAvailable] = useState(false);

  const { id } = useParams();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { socialLinks, avialableSlots = [] } = branch ?? {};
  const members = branch?.members || [];

  const [currentTab, setCurrentTab] = useState(0);
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const fetchBranch = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_API}/business/public/${id}`,
        // `${process.env.REACT_APP_HOST_API}/business/public/availbilties/${id}?timezone=${timeZone}`,
        {}
      );
      const { body } = res.data;
      setLoading(false);
      setbranch(body);
    } catch (error) {
      setLoading(false);
      console.error('There was an error making the request:', error);
    }
  };
  useEffect(() => {
    if (!branch) {
      fetchBranch();
    }
  }, [id]);
  const settingsSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,

  };
  const handleBook = () => {
    if (selectedService?.length === 0) {
      enqueueSnackbar('Please select a service first', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return
    }
    if (selectedMember?.length === 0) {
      enqueueSnackbar('Please select a member first', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return
    }

    window.open(
      store_links.customerPlaystore,
      '_blank'
    )
  }

  const handle = useDoubleClick({
    click: (event, service) => {
      event.stopPropagation();
      setSelectServ(service);
    },

    doubleClick: (event, service) => {
      event.stopPropagation();
      setSelectServ(null);
      setSelectMemb(null);
    }
  });
  const handleClick = useDoubleClick({
    click: (event, member) => {
      event.stopPropagation();
      setSelectMemb(member);
      setLoadingAvailable(true)
      axios.post(
        `${process.env.REACT_APP_HOST_API}/business/public/availbilties/${id}`,
        {
          timezone: timeZone,
          services: [
            {
              service: selectServ?._id,
              professional: member?._id
            }
          ]
        }
      ).then((resp) => {
        if (resp) {
          setLoadingAvailable(false)
          setAvailabeSlots(resp?.data?.body?.avialableSlots)
        }

      })
    },
    doubleClick: (event, member) => {
      event.stopPropagation();
      setSelectMemb(null)
    }
  })

  return (
    <Container
      sx={{
        pt: 15,
        pb: 10,
        minHeight: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid
          xs={24}
          md={24}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '300px',
              minHeight: '300px',
              backgroundImage: branch?.banner
                ? `url(${branch?.banner})`
                : 'linear-gradient(to right, #ccc, #ddd)', 
              backgroundSize: branch?.banner ? '100% auto' : 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '5px',
              display: 'block',
              position: 'relative',
              marginBottom: '50px',
              imageRendering: '-webkit-optimize-contrast',
              '@media (min-width: 768px)': {
                backgroundSize: branch?.banner ? 'cover' : 'contain',
              },
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              color: '#666',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            {!branch?.banner && <span>No Banner Available</span>}
            <Box sx={{
              position: 'absolute',
              bottom: { md: -30, xs: -20 },
              left: { md: 30, xs: 20 },
              borderRadius: '10px',
              border: '3px solid white',
              width: { md: '130px', xs: '100px' },
              height: { md: '130px', xs: '100px' },
              backgroundImage: branch?.business?.image
                ? `url(${branch.business.image})`
                : 'linear-gradient(to right, #aaa, #bbb)', 
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '14px',
            }} >
              {!branch?.business?.image && <span>No Image</span>}
              </Box>

            {/* <Typography
              variant="h3"
              align="center"
              sx={{
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '0.5rem 1rem',
                borderRadius: '8px',
              }}
            >
              {branch?.name}
            </Typography> */}
          </Box>
          <Stack sx={{ mb: 2 }} direction={{ xs: 'column', md: 'row' }} spacing={2}>
            {/* <Avatar
                alt="image"
                src={branch?.image}
                sx={{ marginTop: '.5rem', width: 56, height: 56 }}
              /> */}
            <Typography variant="h3">
              {branch?.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Rating value={branch?.averageRating || 0} size="large" readOnly />
              <Typography color={'primary'} variant="h5" sx={{ ml: 1 }}>({branch?.totalReviews || 0}) </Typography>
            </Box>
          </Stack>
          <Box sx={{ width: "100%", display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between" }}>
            <Box sx={{ width: { md: "45%", xs: "100%" } }}>
              <Typography variant="h5" gutterBottom>
                About Us
              </Typography>
              <Card>
                <Typography sx={{ p: 2 }} variant="body2" color="text.secondary">
                  {branch?.description || 'This business has not provided information yet.'}
                </Typography>
              </Card>
            </Box>
            <Box sx={{ width: { md: "45%", xs: "100%" } }}>
              <Typography color="info" variant="h5" sx={{ mb: 2 }}>
                Information
              </Typography>
              {loading ? (
                <Stack spacing={2}>
                  {/* Skeleton Loaders */}
                  {[...Array(6)].map((_, index) => (
                    <Skeleton key={index} variant="rounded" width="100%" height="2rem" />
                  ))}
                </Stack>
              ) : (
                <Stack spacing={2}>
                  <Item sx={{ display: 'flex' }}>
                    <Iconify icon="mdi-light:email" sx={{ mr: 2 }} />
                    <Tooltip title={branch?.business?.email}>
                      <Typography
                        sx={{
                          maxWidth: '15rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {branch?.business.email || 'No email provided'}
                      </Typography>
                    </Tooltip>
                  </Item>
                  {/* Contact Number */}
                  <Item sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Contact us on WhatsApp" arrow>
                      <a
                        href={`https://wa.me/${branch?.contactNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
                        <Typography>{branch?.contactNumber}</Typography>
                      </a>
                    </Tooltip>
                  </Item>


                  {/* Address */}
                  <Item sx={{ display: 'flex' }}>
                    <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
                    <Tooltip title={branch?.address?.line1}>
                      <Typography
                        sx={{
                          maxWidth: '15rem',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {branch?.address?.line1 || 'No address provided'}
                      </Typography>
                    </Tooltip>
                  </Item>
                  <Box
                    sx={{
                      display: 'flex',
                      overflowX: 'auto',  // Enable horizontal scrolling
                      gap: 2,  // Space between items
                      padding: '10px'
                    }}
                  >


                    {[
                      { icon: 'tabler:world', link: socialLinks?.website, label: 'website' },
                      { icon: 'skill-icons:instagram', link: socialLinks?.instargram, label: 'Instagram' },
                      { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'LinkedIn' },

                    ].filter((v) => v.link).map((social, idx) => (
                      <>
                        <Box key={idx} sx={{ textAlign: 'center', minWidth: '100px' }}>
                          <a
                            // href={social.link}
                            href={social.link.startsWith("http") ? social.link : `https://${social.link}`} 
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Box
                              sx={{
                                padding: '10px',
                                border: '1px solid gray',
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFF',
                                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                              <Iconify icon={social.icon} width={35} height={35} color="black" />
                            </Box>
                            <Typography sx={{ mt: 1, fontWeight: 'bold', fontSize: '14px', textAlign: 'center', color: '' }} color='inherit'>{social.label}</Typography>
                          </a>
                        </Box>
                      </>

                    ))}

                  </Box>

                </Stack>
              )}
            </Box>
          </Box>
          <Typography color="info" variant="h5" sx={{ mb: 2, mt: 1 }}>
            Services
          </Typography>
          {loading ? (
            <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
          ) : branch?.services?.length ? (
            <ServiceCards services={branch?.services} setSelectedService={setSelectedService} selectedService={selectedService} handle={handle} selectServ={selectServ} />
          ) : (
            <Card>
              <Typography sx={{ p: 2 }} variant="body2" color="text.secondary">
                {'This business have not provided services yet.'}
              </Typography>
            </Card>
          )}{' '}
        </Grid>
        {selectServ && <>
          <>
            {/* <Grid  spacing={3} sx={{ px: 2,border:'1px solid red'}}> */}
            <Grid item xs={12}>
              <Typography color="info" variant="h5" sx={{}}>
                Team Members
              </Typography>
            </Grid>

            {members.map((member) => (
              <Grid item xs={12} sm={6} md={4} key={member._id}>
                <MemberCard
                  member={member}
                  setSelectedMember={setSelectedMember}
                  selectedMember={selectedMember}
                  handleClick={handleClick}
                  selectMemb={selectMemb}

                />
              </Grid>
            ))}
            {/* </Grid> */}

          </>
        </>}
        <Grid container spacing={2}>
          {/* About Section */}


          {/* Availabilities Section */}
          {selectServ && selectMemb && <Grid item xs={12} md={12}>
            <Typography color="info" variant="h5" sx={{ mb: 2, ml: 2 }}>
              Availabilities
            </Typography>
            {loadingAvailable ? <>
              <Stack spacing={2} sx={{ width: { md: "500px", xs: "100%" }, minWidth: "250px" }}>
                {[...Array(6)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    sx={{ width: "100%" }}
                    height="3rem"
                  />
                ))}
              </Stack>
            </> :
              <>
                <Tabs
                  sx={{ marginLeft: '-30px' }}
                  value={currentTab}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons={true}
                  allowScrollButtonsMobile
                >
                  {availableSlots?.map((tab, index) => (
                    <Tab
                      key={tab.date}
                      label={
                        <Card
                          sx={{
                            mb: 1,
                            p: 1,
                            border: tab.isOpen ? '2px solid gray' : '2px solid red',
                            transition: 'border 0.3s',
                            cursor: 'pointer',
                          }}
                        >
                          <Stack spacing={0} alignItems="center">
                            <Typography variant="body2">
                              {moment(tab.date).format('dddd')} {/* Show day */}
                            </Typography>
                            <Typography variant="body2">
                              {moment(tab.date).format('MMMM D, YYYY')} {/* Show date */}
                            </Typography>
                          </Stack>
                        </Card>
                      }
                      value={index}
                      onClick={() => setCurrentTab(index)}
                    />
                  ))}
                </Tabs>

                {/* Display available times when a date is selected */}
                {availableSlots[currentTab]?.slots?.length ? (
                  <Box
                    sx={{
                      height: '300px', // Set a fixed height, adjust as needed
                      overflowY: 'auto',
                      padding: '10px', // Enable vertical scrolling
                    }}
                  >
                    <Stack
                      sx={{
                        mt: 2,
                      }}
                      spacing={2}
                    >
                      {availableSlots[currentTab]?.slots?.map((item, idx) => (
                        // <Card key={idx} sx={{ p: 2 }}>
                        //   {moment(item?.startTime).tz(timeZone).format('h:mm A')} -{' '}
                        //   {moment(item?.endTime).tz(timeZone).format('h:mm A')}
                        // </Card>
                        <Card
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 1,
                          }}
                        >
                          <Typography>
                            {moment(item?.startTime).tz(timeZone).format('h:mm A')} -{' '}
                            {moment(item?.endTime).tz(timeZone).format('h:mm A')}
                          </Typography>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={handleBook}
                          >
                            <Tooltip title="Go to the app to make a booking" arrow>
                              Book Now
                            </Tooltip>
                          </Button>
                        </Card>
                      ))}
                    </Stack>
                  </Box>
                ) : (
                  <Card sx={{ mt: 2 }}>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      No available times for this date.
                    </Typography>
                  </Card>
                )
                }
              </>}
          </Grid>}
        </Grid>
      </Grid>
      <Box>
        <m.span variants={varFade().inUp}>
          <Box
            display={'flex'}
            rowGap={2}
            columnGap={2}
            marginTop="1rem"
            flexWrap={'wrap'}
            sx={{
              mt: 2,
              justifyContent: {
                lg: 'start',
                md: 'start',
                sm: 'center',
                xs: 'center',
              },
            }}
          >
            <DownloadButtons
              onClick={() => window.location.replace('/')}
              image={'/assets/icons/home/home.png'}
              title="Home"
              description="Go to Home"
            />
            <DownloadButtons
              image={'/assets/icons/app/apple.svg'}
              title="App Store"
              description="Download on the"
            />
            <DownloadButtons
              onClick={() =>
                window.open(
                  store_links.customerPlaystore,
                  '_blank'
                )
              }
              image={'/assets/icons/app/playstore.svg'}
              title="Play Store"
              description="Get it on"
            />
          </Box>
        </m.span>
      </Box>
    </Container>
  );
}
// <Grid
// item
// xs={12}
// md={4}
// sx={{
//   display: 'flex',
//   flexDirection: 'column',
//   p: (theme) => theme.spacing(2, 3, 3, 3),
// }}
// >
// <Typography color="info" variant="h5" sx={{ mb: 2 }}>
//   Information
// </Typography>
// {loading ? (
//   <Stack spacing={2}>
//     {/* Skeleton Loaders */}
//     {[...Array(6)].map((_, index) => (
//       <Skeleton key={index} variant="rounded" width="100%" height="2rem" />
//     ))}
//   </Stack>
// ) : (
//   <Stack spacing={2}>
//     <Item sx={{ display: 'flex' }}>
//       <Iconify icon="mdi-light:email" sx={{ mr: 2 }} />
//       <Tooltip title={branch?.business?.email}>
//         <Typography
//           sx={{
//             maxWidth: '15rem',
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {branch?.business.email || 'No email provided'}
//         </Typography>
//       </Tooltip>
//     </Item>
//     {/* Contact Number */}
//     <Item sx={{ display: 'flex', alignItems: 'center' }}>
//       <Tooltip title="Contact us on WhatsApp" arrow>
//         <a
//           href={`https://wa.me/${branch?.contactNumber}`}
//           target="_blank"
//           rel="noopener noreferrer"
//           style={{
//             textDecoration: 'none',
//             color: 'inherit',
//             display: 'flex',
//             alignItems: 'center',
//           }}
//         >
//           <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
//           <Typography>{branch?.contactNumber}</Typography>
//         </a>
//       </Tooltip>
//     </Item>


//     {/* Address */}
//     <Item sx={{ display: 'flex' }}>
//       <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
//       <Tooltip title={branch?.address?.line1}>
//         <Typography
//           sx={{
//             maxWidth: '15rem',
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//           }}
//         >
//           {branch?.address?.line1 || 'No address provided'}
//         </Typography>
//       </Tooltip>
//     </Item>
//     <Box
//       sx={{
//         display: 'flex',
//         overflowX: 'auto',  // Enable horizontal scrolling
//         gap: 2,  // Space between items
//         padding: '10px'
//       }}
//     >


//       {[
//         { icon: 'tabler:world', link: socialLinks?.website, label: 'website' },
//         { icon: 'skill-icons:instagram', link: socialLinks?.instargram, label: 'Instagram' },
//         { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'LinkedIn' },

//       ].filter((v) => v.link).map((social, idx) => (
//         <>
//           <Box key={idx} sx={{ textAlign: 'center', minWidth: '100px' }}>
//             <a
//               href={social.link}
//               target="_blank"
//               rel="noopener noreferrer"
//               style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
//             >
//               <Box
//                 sx={{
//                   padding: '10px',
//                   border: '1px solid gray',
//                   borderRadius: '50%',
//                   width: '60px',
//                   height: '60px',
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   backgroundColor: '#FFF',
//                   boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
//                 }}
//               >
//                 <Iconify icon={social.icon} width={35} height={35} color="black" />
//               </Box>
//               <Typography sx={{ mt: 1, fontWeight: 'bold', fontSize: '14px', textAlign: 'center', color: '#F0EAFF' }}>{social.label}</Typography>
//             </a>
//           </Box>
//         </>

//       ))}

//     </Box>

//   </Stack>
// )}
// </Grid>


const MemberCard = ({ member, setSelectedMember, selectedMember, handleClick, selectMemb }) => {
  let isSelected = selectMemb?._id === member?._id

  const [open, setOpen] = useState(false);

  const handleOpen = (e, member) => {
    if (e.target.closest("button")) return;
    e.stopPropagation();
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMember(null); // Reset the selected member when closing the modal
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: isSelected ? 'primary.light' : 'background.paper',
          p: 3,
          width: '100%',
          cursor: 'pointer',
          boxShadow: 3,
          borderRadius: 2,
        }}
        id="member"
        onClick={(e) => handleOpen(e, member)} // Open the modal on card click
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Avatar src={member.user.image} sx={{ width: 56, height: 56, mr: 2 }} />
          <Tooltip title={member.user.name} placement="top">
            <Typography variant="subtitle2" noWrap>
              {member.user.name?.length > 10 ? member.user.name.slice(0, 10) + "..." : member.user.name}
            </Typography>
          </Tooltip>
        </Box>

        <Box sx={{ marginLeft: 'auto', textAlign: 'right' }}>
          <Typography variant="body2" color={isSelected ? 'light' : 'text.secondary'}>
            {member.jobTitle?.length > 12 ? member.jobTitle.slice(0, 12) + "..." : member.jobTitle}
          </Typography>
        </Box>

        <Button size="small" color="primary" onClick={handleClick(member)} sx={{ ml: 2 }}>
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </Card>

      {/* Modal for viewing member details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="member-details-title"
        aria-describedby="member-details-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: '90%',
            maxWidth: 500,
          }}
        >
          {selectedMember && (
            <>
              {/* Member Info Section */}
              <Box
                sx={{
                  mb: 3,
                  textAlign: 'center',
                }}
              >
                <img
                  src={selectedMember.user.image || '/placeholder.jpg'}
                  alt={selectedMember.user.name}
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                    borderRadius: '8px',
                    margin: '0 auto',
                  }}
                />
              </Box>

              <Typography variant="h6" gutterBottom>
                {selectedMember.user.name}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {selectedMember.jobTitle}
              </Typography>

              {/* Contact Information */}
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                <strong>Email:</strong> {selectedMember.user.email}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                <strong>Phone:</strong> {selectedMember.user.mobile}
              </Typography>

              {/* Availability */}
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                <strong>Available for Home Service:</strong> {selectedMember.availableForHomeService ? 'Yes' : 'No'}
              </Typography>

              {/* Working Hours */}
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Working Hours:</strong>
                </Typography>
                {selectedMember.openingHours.map((hour, index) => (
                  <Typography variant="body2" color="text.secondary" key={index}>
                    {hour.day}: {hour.isOpen ? `${hour.startTime} - ${hour.endTime}` : 'Closed'}
                  </Typography>
                ))}
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};




const members = [
  {
    "_id": "673c5efdce577cf88278f8a8",
    "user": {
      "_id": "673c5efdce577cf88278f8a6",
      "name": "Hashir",
      "email": "glownigemp108@gmail.com",
      "mobile": "+923070412536",
      "gender": "Male",
      "image": "https://timezzi-bucket.s3.amazonaws.com/1732009723387.jpeg"
    },
    "jobTitle": "Hair Styling",
    "openingHours": [
      {
        "day": "Monday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "20:00",
        "_id": "673f205017e96eaffafc2ef7"
      },
      {
        "day": "Tuesday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "20:00",
        "_id": "673f205017e96eaffafc2ef8"
      },
      {
        "day": "Wednesday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "20:00",
        "_id": "673f205017e96eaffafc2ef9"
      },
      {
        "day": "Thursday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "20:00",
        "_id": "673f205017e96eaffafc2efa"
      },
      {
        "day": "Friday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "20:00",
        "_id": "673f205017e96eaffafc2efb"
      },
      {
        "day": "Saturday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": false,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "673f205017e96eaffafc2efc"
      },
      {
        "day": "Sunday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": false,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "673f205017e96eaffafc2efd"
      }
    ],
    "branch": "6737311c2a95c03a5651e958",
    "availableForHomeService": true,
    "isAvailable": true,
    "isActive": true,
    "createdAt": "2024-11-19T09:48:45.652Z",
    "updatedAt": "2024-11-21T11:58:08.945Z",
    "__v": 0,
    "reviews": [],
    "totalReviews": 0,
    "averageRating": null
  },
  {
    "_id": "673733362a95c03a5651ea14",
    "user": {
      "_id": "673733362a95c03a5651ea12",
      "name": "Zawar Worker",
      "email": "cwaliimrandev@gmail.com",
      "mobile": "+923005098556",
      "gender": "Male",
      "image": "https://timezzi-bucket.s3.amazonaws.com/1733729924872.jpeg"
    },
    "jobTitle": "Barber Professional",
    "openingHours": [
      {
        "day": "Monday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c035a"
      },
      {
        "day": "Tuesday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c035b"
      },
      {
        "day": "Wednesday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c035c"
      },
      {
        "day": "Thursday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c035d"
      },
      {
        "day": "Friday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "21:00",
        "_id": "6756e0b6ddf4b8c0879c035e"
      },
      {
        "day": "Saturday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": true,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c035f"
      },
      {
        "day": "Sunday",
        "break": {
          "startTime": "13:00",
          "endTime": "14:00"
        },
        "isOpen": false,
        "startTime": "08:00",
        "endTime": "17:00",
        "_id": "6756e0b6ddf4b8c0879c0360"
      }
    ],
    "branch": "6737311c2a95c03a5651e958",
    "availableForHomeService": true,
    "isAvailable": true,
    "isActive": true,
    "createdAt": "2024-11-15T11:40:38.546Z",
    "updatedAt": "2024-12-09T12:21:10.495Z",
    "__v": 0,
    "reviews": [],
    "totalReviews": 0,
    "averageRating": null
  },



]